<script lang="ts" setup>
import { debounce } from "lodash";
import { ref } from "vue";

const props = defineProps<{ modelValue: string | null; id: string | null }>();
const emit = defineEmits(["update:modelValue"]);
const id = props.id || undefined;

const display_text = ref(to_phone_string(props.modelValue));

function to_phone_string(value: string | null) {
  if (value === null || value === undefined) {
    return "";
  }
  const cleaned = value.replace(/\D/g, "");
  return (
    "(" +
    cleaned.substring(0, 3) +
    ") " +
    cleaned.substring(3, 6) +
    " - " +
    cleaned.substring(6, 10)
  );
}
function to_clean_string(value: string | null) {
  if (value === null || value === undefined) {
    return "";
  }
  return value.replace(/\D/g, "");
}
const keyup = debounce((ev) => {
  if (ev.key === "Backspace" || ev.key === "Delete") {
    return;
  }
  display_text.value = to_phone_string(display_text.value);
  emit("update:modelValue", to_clean_string(display_text.value));
}, 100);
</script>
<template>
  <input
    type="text"
    v-model="display_text"
    @keyup="keyup"
    placeholder="(555) 555-5555"
    :id="id"
  />
</template>
